// Generated by Framer (f082bd6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/rerWrmQ65";

const enabledGestures = {b88z41vz_: {hover: true}};

const cycleOrder = ["rObxpNGp_", "zNzyWdzz3", "b88z41vz_"];

const serializationHash = "framer-3EmJC"

const variantClassNames = {b88z41vz_: "framer-v-stukul", rObxpNGp_: "framer-v-409wy3", zNzyWdzz3: "framer-v-prt7qe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Complete: "zNzyWdzz3", Inactive: "b88z41vz_", Playing: "rObxpNGp_"}

const getProps = ({click, height, id, title, width, ...props}) => { return {...props, dDnGYdLN4: title ?? props.dDnGYdLN4 ?? "STEP 1", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "rObxpNGp_", vYMcHT6Mz: click ?? props.vYMcHT6Mz} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dDnGYdLN4, vYMcHT6Mz, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rObxpNGp_", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k1ht34 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (vYMcHT6Mz) {const res = await vYMcHT6Mz(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-409wy3", className, classNames)} data-framer-name={"Playing"} data-highlight layoutDependency={layoutDependency} layoutId={"rObxpNGp_"} onTap={onTap1k1ht34} ref={ref ?? ref1} style={{backgroundColor: "var(--token-f213e283-24d0-40a3-a2dc-bca1da07b971, rgb(16, 19, 28))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "inset 0px 1px 1px 0px rgba(207, 231, 255, 0.2)", ...style}} variants={{"b88z41vz_-hover": {backgroundColor: "var(--token-eb09dbbf-ef85-4b7f-81a5-44e9b062efb7, rgb(4, 7, 13))"}}} {...addPropertyOverrides({"b88z41vz_-hover": {"data-framer-name": undefined}, b88z41vz_: {"data-framer-name": "Inactive"}, zNzyWdzz3: {"data-framer-name": "Complete"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1u349i7"} data-styles-preset={"rerWrmQ65"}>STEP 1</motion.p></React.Fragment>} className={"framer-1gekb2v"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"X7vL8UOYi"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={dDnGYdLN4} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3EmJC.framer-19oxzmg, .framer-3EmJC .framer-19oxzmg { display: block; }", ".framer-3EmJC.framer-409wy3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 44px; justify-content: center; overflow: hidden; padding: 13px; position: relative; width: 134px; will-change: var(--framer-will-change-override, transform); }", ".framer-3EmJC .framer-1gekb2v { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3EmJC.framer-409wy3 { gap: 0px; } .framer-3EmJC.framer-409wy3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-3EmJC.framer-409wy3 > :first-child { margin-left: 0px; } .framer-3EmJC.framer-409wy3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 134
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zNzyWdzz3":{"layout":["fixed","fixed"]},"b88z41vz_":{"layout":["fixed","fixed"]},"ubAllJmkG":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dDnGYdLN4":"title","vYMcHT6Mz":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerkp3q5qt1W: React.ComponentType<Props> = withCSS(Component, css, "framer-3EmJC") as typeof Component;
export default Framerkp3q5qt1W;

Framerkp3q5qt1W.displayName = "Component / Step";

Framerkp3q5qt1W.defaultProps = {height: 44, width: 134};

addPropertyControls(Framerkp3q5qt1W, {variant: {options: ["rObxpNGp_", "zNzyWdzz3", "b88z41vz_"], optionTitles: ["Playing", "Complete", "Inactive"], title: "Variant", type: ControlType.Enum}, dDnGYdLN4: {defaultValue: "STEP 1", displayTextArea: false, title: "Title", type: ControlType.String}, vYMcHT6Mz: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(Framerkp3q5qt1W, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})